<template>
  <AppPage>
    <div class="app-width">
      <div>
        <youtube :video-id="$route.query.v" />
        <h1>{{ data.title }}</h1>
        <p>
          {{ data.description }}
        </p>
      </div>
      <div>
        <h2>Chat</h2>
        <div
          v-for="message in data.messages"
          :key="message.id"
        >
          <div>
            <img :src="message.author.avatar">
            <div>
              <h3>{{ message.author.name }}</h3>
              <p>{{ message.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

export default Vue.extend({
  components: {
    AppPage
  },
  data(){
    return {
      data: null
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    async loadData(){
      console.log(this.$route.query.v);
      const results = await this.$store.dispatch('videos/getDataForUrl', `https://youtube.com/watch?v=${this.$route.query.v}`);

      this.data = results.data;
      console.log(results.data);
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';

.app-width{
  max-width: 1200px;
  overflow: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;

}

h1{
  font-size: 1.5rem;
}
</style>
